import React from "react";
import styled from "styled-components";

export const Order = ({
  className = "",
  number,
  src,
  title,
  content,
  content2,
  detail,
}) => {
  if (className === "left") {
    return (
      <Wrapper className={className}>
        <div className="number">{number}</div>
        <img className="order" src={src} alt="" />
        <div className="order-list">
          <div className="order-title">{title}</div>
          <div className="order-content">{content}</div>
          <div className="order-content">{content2}</div>
        </div>
      </Wrapper>
    );
  } else {
    return (
      <WrapperR>
        <div className="order-list">
          <div className="order-title">{title}</div>
          <div className="order-content">{content}</div>
          <div className="order-detail">{detail}</div>
        </div>
        <img className="order" src={src} alt="" />
        <div className="number">{number}</div>
      </WrapperR>
    );
  }
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100vh;
  max-width: 1000px;
  min-width: 900px;
  margin-top: 30px;
  border: 1px solid #e1e1e5;
  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 150px;
    font-size: 80px;
    background-color: orange;
    color: white;
    border-right: 1px solid lightgray;
  }
  .order {
    height: 80px;
    margin: 0 50px 0 30px;
  }
  .order-list {
    .order-title {
      display: flex;
      font-size: 28px;
      color: orange;
      font-family: NanumBold;
    }
    .order-content {
      display: flex;
      font-family: "AppleSDGothicNeo";
      font-size: 14px;
      line-height: 14px;
      margin-top: 12px;
    }
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    width: 90%;
    min-width: 0;
    margin-left: 5%;
    .number {
      width: 50px;
      height: 75px;
    }
    .order {
      height: 50px;
      margin: 0 10px 0 10px;
    }
    .order-list {
      width: 100%;
      max-width: 600px;
      text-align: left;
      .order-title {
        font-size: 12px;
      }
      .order-content {
        font-size: 10px;
      }
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    width: 90%;
    min-width: 0;
    margin-left: 5%;
    .number {
      width: 50px;
      height: 75px;
    }
    .order {
      height: 50px;
      margin: 0 10px 0 10px;
    }
    .order-list {
      width: 100%;
      max-width: 330px;
      text-align: left;
      .order-title {
        font-size: 12px;
      }
      .order-content {
        font-size: 10px;
      }
    }
  }
`;

const WrapperR = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100vh;
  max-width: 1000px;
  min-width: 900px;
  margin-top: 30px;
  border: 1px solid #e1e1e5;
  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 150px;
    font-size: 80px;
    background-color: orange;
    color: white;
    border-right: 1px solid lightgray;
  }
  .order {
    height: 80px;
    margin: 0 50px 0 30px;
  }
  .order-list {
    .order-title {
      display: flex;
      justify-content: flex-end;
      font-size: 28px;
      color: orange;
      font-family: NanumBold;
    }
    .order-content {
      display: flex;
      justify-content: flex-end;
      font-family: "AppleSDGothicNeo";
      font-size: 14px;
      line-height: 14px;
      margin-top: 12px;
    }
    .order-detail {
      text-align: right;
      font-family: "AppleSDGothicNeo";
      font-size: 12px;
      line-height: 12px;
      margin-top: 12px;
    }
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    width: 90%;
    min-width: 0;
    margin-left: 5%;
    .number {
      width: 50px;
      height: 75px;
    }
    .order {
      height: 50px;
      margin: 0 10px 0 10px;
    }
    .order-list {
      width: 100%;
      max-width: 600px;
      text-align: center;
      .order-title {
        font-size: 12px;
      }
      .order-content {
        font-size: 10px;
      }
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    width: 90%;
    min-width: 0;
    margin-left: 5%;
    .number {
      width: 50px;
      height: 75px;
    }
    .order {
      height: 50px;
      margin: 0 10px 0 10px;
    }
    .order-list {
      width: 100%;
      max-width: 330px;
      text-align: center;
      .order-title {
        font-size: 12px;
      }
      .order-content {
        font-size: 10px;
      }
      .order-detail {
        font-size: 6px;
      }
    }
  }
`;
