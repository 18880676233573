import React from "react";
import styled from "styled-components";

export const Apply = () => {
  return (
    <>
      <div className="apply"></div>
      <Wrapper>
        <div className="apply-inner">
          <div className="apply-title">
            <span>
              {"<"}문의하기{">"}
            </span>
          </div>
          <textfiled className="apply-form">
            <div className="apply-line">
              <label>*</label>
              <input placeholder="Name" className="form-name" />
            </div>
            <div className="apply-line">
              <label>*</label>
              <input placeholder="Email" className="form-email" />
            </div>
            <div className="form-textarea">
              <label>*</label>
              <textarea placeholder="MESSAGE" className="form-message" />
            </div>
          </textfiled>
          <h3 className="apply-agree">
            <label>*</label>
            <span>개인정보 수집 및 동의</span>
          </h3>
          <ul className="apply-detail">
            <li>1. 개인정보의 수집 및 이용목적 : 문의에 대한 답변</li>
            <li>2. 수집하려는 개인정보의 항목 : 성명, 이메일</li>
            <li>
              3. 동의를 거부할 수 있으며, 동의 거부시 문의에 대한 응답이
              제공되지 않습니다.
              <input type="checkbox" className="apply-checkbox" />
            </li>
          </ul>
          <div className="button-wrapper">
            <button className="apply-button">문의하기</button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  .apply-inner {
    text-align: left;
    margin-top: 60px;

    .apply-agree {
      color: white;
      font-family: "NotoSansKR-Medium";
      margin: 20px 0 0 0;
      span {
        /* margin-left: 8px; */
      }
    }
  }
  .apply-title {
    color: white;
    font-family: "NotoSansKR-Medium";
    font-size: 20px;
    font-weight: bold;
  }
  .apply-form {
    label {
      color: white;
    }
    .form-name,
    .form-email {
      margin-top: 15px;
      background-color: white;
      border: none;
      width: 200px;
      height: 30px;
      &:focus {
        outline: none;
      }
    }
    .form-textarea {
      display: flex;
      align-items: baseline;
    }
    .form-message {
      margin-top: 15px;
      background-color: white;
      border: none;
      resize: none;
      width: 500px;
      height: 200px;
      font-family: "NotoSansKR-Medium";

      &:focus {
        outline: none;
      }
    }
  }
  .apply-detail {
    padding-left: 12px;
    color: white;
    font-family: "NotoSansKR-Medium";
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    .apply-button {
      outline: none;
      border: none;
      width: 300px;
      height: 40px;
      border-radius: 6px;
    }
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    .apply-inner {
      .apply-agree {
        margin-left: 20px;
      }
      .apply-line {
        padding-left: 20px;
        .form-name {
          width: 300px;
        }
        .form-email {
          width: 300px;
        }
      }
      .form-textarea {
        padding-left: 20px;
      }
    }
    .apply-detail {
      margin-left: 10px;
    }
    .apply-title {
      padding-left: 20px;
    }
    .apply-form {
      .form-message {
        width: 300px;
      }
    }
  }
`;
