import React from "react";
import src from "@assets/light.png";
import quote from "@assets/quote.png";
import styled from "styled-components";
import useScrollFadeIn from "../hooks/useScrollFadeIn";

export const Intro = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0),
  };
  return (
    <>
      <div className="intro"></div>
      <Wrapper {...animatedItem[0]}>
        <div className="word-wrap">
          <div className="word-box">
            <p>내가 쓴 가사로 내가 부른 노래를 </p>
            <p>
              <strong>모든 사람이 들을 수 있다면?</strong>
            </p>
          </div>
        </div>
        <div className="icon-light">
          <img src={src} />
        </div>
        <div className="double-quote">
          <img src={quote} className="quote-left" />
          <div>
            <p>
              <strong>노래에 자신이 없더라도 괜찮아요</strong>
            </p>
            <p>
              <strong>당신의 소중한 순간을 음악으로 만들 수 있습니다</strong>
            </p>
          </div>
          <img src={quote} className="quote-right" />
        </div>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.div`
  background-color: #f3f0ea;
  padding: 80px;
  .word-wrap {
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 20px;
    &::after {
      content: "";
      position: absolute;
      left: -10px;
      right: 0;
      top: 150px;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-top: 15px solid white;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
    }
  }
  .word-box {
    width: 400px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-radius: 24px;
    margin-right: 2px;
    p {
      margin: 8px 0 0 0;
    }
  }
  .icon-light {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    img {
      width: 300px;
    }
  }
  .double-quote {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-size: 18px;
    .quote-left {
      width: 30px;
      height: 30px;
      margin-right: 20px;
      margin-top: 10px;
    }
    .quote-right {
      width: 30px;
      height: 30px;
      transform: rotate(180deg);
      margin-left: 20px;
      margin-top: 50px;
    }
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and(max-width:767px) {
    // mobile 가로
  }
  @media only screen and (max-width: 479px) {
    padding: 40px;
    // mobile 세로

    .word-box {
      font-size: 16px;
    }
    .icon-light {
      img {
        width: 200px;
      }
    }
    .double-quote {
      font-size: 12px;
    }
  }
`;
