import React, { useState, useEffect } from "react";
import styled from "styled-components";
import NavBar from "../components/Nav/NavBar";
import Main from "../components/Main";
import MainBottom from "@src/components/MainBottom";
import { Intro } from "@src/components/Intro";
import { IntroContent } from "@src/components/IntroContent";
import { Difference } from "@src/components/Difference";
import { Process } from "@src/components/Process";
import { Process2 } from "@src/components/Process2";
import { Process3 } from "@src/components/Process3";
import { Banner } from "@src/components/Banner";
import { QnA } from "@src/components/QnA";
import { Team } from "@src/components/Team";
import { Review } from "@src/components/Review";
import { Apply } from "@src/components/Apply";
import { Footer } from "@src/components/items/Footer";

function Home() {
  return (
    <Wrapper>
      <NavBar option="home" />
      <Main />
      <MainBottom />
      <Intro />
      <IntroContent />
      <Difference />
      <Process />
      <Process2 />
      <Process3 />
      {/* <Banner /> */}
      <QnA />
      <Team />
      <Review />
      <Apply />
      <Footer />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  overflow: auto;
`;
export default Home;
