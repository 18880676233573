import React from "react";
import styled from "styled-components";
import { Order } from "./items/Order";
import useScrollFadeIn from "../hooks/useScrollFadeIn";
import ic_p1 from "@assets/ic-p1.png";
import ic_p2 from "@assets/ic-p2.png";
import ic_p3 from "@assets/ic-p3.png";

export const Process = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0),
  };
  return (
    <>
      <div className="process"></div>
      <Wrapper {...animatedItem[0]}>
        <div className="process-wrap">
          <Title>
            <div className="process-sub">컨설팅부터 음원발매까지</div>
            <H4 className="process-main">
              <span>듣는 하루 과정</span>
            </H4>
          </Title>
          <Contents>
            <H5>
              <span>컨설팅 및 피드백</span>
            </H5>
            <div>
              <Order
                className="left"
                number={1}
                src={ic_p1}
                title="컨설팅"
                content="하고 싶은 느낌의 곡을 구체적으로 컨설팅 합니다"
                detail="(메모해 두었던 가사의 컨셉이나 흥얼 거렸던 멜로디가 있다면 가져와 주세요!)"
              />
              <Order
                className="left"
                number={2}
                src={ic_p2}
                title="가이드 녹음"
                content="전문 작곡가 팀이 INST와 멜로디를 제작한 후 가이드 녹음을 시작합니다"
                detail=""
              />
              <Order
                className="left"
                number={3}
                src={ic_p3}
                title="피드백"
                content="곡 완성이 되면 전달드린 후 피드백을 받아가며 가사를 완성합니다"
                detail=""
              />
            </div>
          </Contents>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 900px;
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    height: auto;
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    height: auto;
  }
`;

const Title = styled.div`
  .process-sub {
    font-size: 20px;
    font-family: NanumBold;
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    .process-sub {
      margin-top: 40px;
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    .process-sub {
      margin-top: 40px;
      font-size: 14px;
    }
  }
`;

export const H4 = styled.h4`
  margin: 0 0 20px 0;
  span {
    font-size: 34px;
    padding-bottom: 10px;
    font-family: NanumExtraBold;
    border-bottom: 2px solid black;
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    margin-top: 8px;
    span {
      font-size: 20px;
      padding-bottom: 8px;
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    margin-top: 8px;
    span {
      font-size: 20px;
      padding-bottom: 8px;
    }
  }
`;
export const H5 = styled.h5`
  display: flex;
  margin: 0 0 20px 0;
  span {
    font-family: NanumExtraBold;
    padding-bottom: 5px;
    font-size: 20px;
    border-bottom: 1px solid black;
  }
  @media only screen and (max-width: 1200px) {
    // tablet
    margin-left: 5%;
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    margin-left: 5%;
    margin-top: 50px;
    span {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    margin-top: 50px;
    margin-left: 5%;
    span {
      font-size: 16px;
    }
  }
`;

const Contents = styled.div``;
