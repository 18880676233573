import React from "react";
import styled from "styled-components";
import src from "@assets/qna.png";
import useScrollFadeIn from "../hooks/useScrollFadeIn";

export function QnA() {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0),
  };
  return (
    <Wrapper>
      <div {...animatedItem[0]} className="qna-wrap">
        <div className="qna-title">
          <span>Q{"&"}A</span>
        </div>
        <div className="question">
          <div className="qna-q">
            <div className="qna-big">Q. </div>
            <div>발매된 음원은 어디에서나 사용할 수 있나요?</div>
          </div>
          <div className="qna-a">
            <div className="qna-big">A. </div>
            <div>
              네 가능합니다! 음원의 사용은 언제 어디서든 사용이 가능합니다.
              <div>
                {
                  "(단, 참여자님의 음원은 듣는하루의 프로모션에 사용될 수 있습니다.)"
                }
              </div>
            </div>
          </div>
        </div>
        <div className="question">
          <div className="qna-q">
            <div className="qna-big">Q. </div>
            <div>
              저는 음악을 배워본 적도 없고, 노래도 못하는데 참여할 수 있을까요?
            </div>
          </div>
          <div className="qna-a">
            <div className="qna-big">A. </div>
            <div>
              그럼요! 참여자님의 실력을 참고하여 만들고, 사전에 레슨도 진행하기
              때문에, 충분히 가능합니다. 부족한 부분은 디렉팅과 후보정으로
              충분히 커버가 가능합니다.
            </div>
          </div>
        </div>
        <div className="question">
          <div className="qna-q">
            <div className="qna-big">Q. </div> 투자해야하는 시간이 많을까요?
          </div>
          <div className="qna-a">
            <div className="qna-big">A. </div>
            <div>
              아니요! 참여자님은 적은 시간투자로도 음원 발매가 가능합니다.
              녹음과 보컬레슨, 가사를 쓰는 시간외에는 전부 저희가 진행하며,
              가사는 카카오톡을 통해 실시간으로 피드백을 진행 하기 때문에 이외의
              별도의 미팅시간은 없습니다.
              <span>(녹음 6시간, 보컬레슨 1시간)</span>
              <div>
                다만 음원의 퀄리티를 위해 충분히 노래연습을 해주시면 좋습니다.
              </div>
            </div>
          </div>
        </div>
        <div className="question">
          <div className="qna-q">
            <div className="qna-big">Q. </div> 어떤 영상을 만들어주나요?
          </div>
          <div className="qna-a">
            <div className="qna-big">A. </div>
            <div>
              가장 특별한 오늘을 기록하고 만드는 과정을 담아냅니다! 누군가를
              위해 또는 나를 위해 가사를 쓰고 녹음을 하는 아름다운 모습을 기록
              해드립니다.
              <span>
                (기본촬영 : 녹음실, 인터뷰 각 1회 촬영 진행, 러닝타임 3-5분
                자세한 영상은 메인 영상 참고)
              </span>
              <span> </span>
            </div>
          </div>
        </div>{" "}
        <div className="question">
          <div className="qna-q">
            <div className="qna-big">Q. </div> 영상은 어떤 용도로 사용할 수
            있나요?
          </div>
          <div className="qna-a">
            <div className="qna-big">A. </div>
            <div>
              완성된 영상은 결혼식, 이벤트, 자기개발, 프로필에 어디서든 사용하실
              수 있습니다!
              <span>(듣는하루 워터마크 적용)</span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff7e8;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .qna-wrap {
    max-width: 900px;
    line-height: 26px;
    .qna-title {
      padding: 20px;
      span {
        font-size: 40px;
        font-weight: 500;
        padding-bottom: 10px;
        font-family: NanumBold;
        color: orange;
        border-bottom: 3px solid orange;
      }
    }
    .question {
      background: white;
      margin-top: 40px;
      text-align: left;
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 5%;
      padding-right: 5%;
      border-radius: 2px;
      font-size: 17px;
      .qna-big {
        color: orange;
        font-weight: 700;
        font-size: 24px;
        margin-right: 10px;
      }
      .qna-q {
        display: flex;
        align-items: baseline;
        margin-bottom: 26px;
      }
      .qna-a {
        display: flex;
        align-items: baseline;
        span {
          font-size: 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    padding: 10px;
    .qna-wrap {
      .qna-title {
        span {
          font-size: 20px;
        }
      }
      .question {
        font-size: 10px;
        .qna-big {
          font-size: 16px;
        }
      }
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    padding: 10px;
    .qna-wrap {
      .qna-title {
        span {
          font-size: 20px;
        }
      }
      .question {
        font-size: 10px;
        .qna-big {
          font-size: 16px;
        }
      }
    }
  }
`;
