import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBFgsaW09PMfUmf8LpJdsyJZyszq6f0rgs",
  authDomain: "project-compose.firebaseapp.com",
  projectId: "project-compose",
  storageBucket: "project-compose.appspot.com",
  messagingSenderId: "49324058010",
  appId: "1:49324058010:web:8b13c111d50f98b3388623",
  measurementId: "G-0KQYKY3KND",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {
  return (
    <Switch>
      <Route path="/" component={Home} />
    </Switch>
  );
}

export default App;
