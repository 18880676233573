import React from "react";
import styled from "styled-components";
import useScrollFadeIn from "../hooks/useScrollFadeIn";
import pic_1 from "@assets/pic-1.jpg";
import pic_2 from "@assets/pic-2.jpg";
import pic_3 from "@assets/pic-3.jpg";
import pic_4 from "@assets/pic-4.jpg";

export const IntroContent = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0),
  };
  return (
    <Wrapper>
      <div {...animatedItem[0]}>
        <div className="intro-title">
          <div className="title-upper">듣는 </div>
          <span>하루는?</span>
        </div>
        <div className="intro-picture">
          <div className="title-rel">
            <img src={pic_1} />
            <div className="title-abs">결혼기념일</div>
          </div>
          <div className="title-rel">
            <img src={pic_2} />
            <div className="title-abs">버킷리스트</div>
          </div>
          <div className="title-rel">
            <img src={pic_3} />
            <div className="title-abs">프로포즈</div>
          </div>
          <div className="title-rel">
            <img src={pic_4} />
            <div className="title-abs">무대공연</div>
          </div>
        </div>
        <div className="intro-texts">
          <div>
            우리가 기억하고 싶은 <strong>결혼 기념일, </strong>
          </div>
          <div>
            평생 기억에 <strong>남을 프로포즈,</strong>
          </div>
          <div>
            죽기 전에 한번쯤은 이뤄보고 싶은 <strong>버킷 리스트</strong>
          </div>
          <div>
            나의 노래로 서보는 <strong>멋진 무대,</strong>
          </div>
          <div className="text-bottom">
            <div>
              <strong>전문 작곡가의 음원 제작부터 유통까지</strong>
            </div>
            <div>
              <strong>
                잊을 수 없는 특별한 날을 만들수 있도록 도와드리는
                프로젝트입니다.
              </strong>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
  .intro-title {
    display: flex;
    flex-direction: column;
    color: orange;
    font-size: 45px;
    width: 200px;
    align-items: flex-start;
    font-family: NanumBold;
    span {
      border-bottom: 2px solid orange;
    }
  }
  .intro-picture {
    display: flex;
    margin-top: 80px;

    .title-rel {
      position: relative;
      img {
        width: 200px;
        height: 300px;
      }
    }
    .title-abs {
      position: absolute;
      display: flex;
      bottom: 3px;
      left: 0;
      width: 200px;
      height: 30px;
      color: white;
      align-items: center;
      justify-content: center;
      font-family: NanumBold;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .title-rel + .title-rel {
      margin-left: 50px;
    }
  }
  .intro-texts {
    margin-top: 80px;
    text-align: left;
    font-size: 18px;
    line-height: 24px;

    .text-bottom {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  @media only screen and (max-width: 1200px) {
    // tablet
    .intro-title {
      font-size: 24px;
    }
    .intro-picture {
      margin-top: 50px;
      .title-rel {
        img {
          width: 150px;
          height: 225px;
        }
      }
      .title-abs {
        width: 150px;
        height: 30px;
        font-size: 13px;
      }
      .title-rel + .title-rel {
        margin-left: 20px;
      }
    }
    .intro-texts {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    .intro-title {
      font-size: 16px;
    }
    .intro-picture {
      margin-top: 30px;
      .title-rel {
        img {
          width: 80px;
          height: 120px;
        }
      }
      .title-abs {
        width: 80px;
        height: 30px;
        font-size: 10px;
      }
      .title-rel + .title-rel {
        margin-left: 10px;
      }
    }
    .intro-texts {
      font-size: 11px;
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    .intro-title {
      font-size: 16px;
    }
    .intro-picture {
      margin-top: 30px;
      .title-rel {
        img {
          width: 80px;
          height: 120px;
        }
      }
      .title-abs {
        width: 80px;
        height: 30px;
        font-size: 10px;
      }
      .title-rel + .title-rel {
        margin-left: 10px;
      }
    }
    .intro-texts {
      font-size: 11px;
    }
  }
`;
