import React from "react";
import styled from "styled-components";
import { Order } from "./items/Order";
import useScrollFadeIn from "../hooks/useScrollFadeIn";
import ic_p7 from "@assets/ic-p7.png";
import ic_p8 from "@assets/ic-p8.png";
import ic_p9 from "@assets/ic-p9.png";

export const Process3 = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0),
  };
  return (
    <Wrapper {...animatedItem[0]}>
      <div>
        <Contents>
          <H4>
            <span>컨설팅 및 피드백</span>
          </H4>

          <Order
            className="left"
            number={7}
            src={ic_p7}
            title="음원 유통"
            content="완성된 음악으로 유통사와 계약합니다."
          />
          <Order
            className="left"
            number={8}
            src={ic_p8}
            title="음원 발매"
            content="총 6주간의 기간을 거쳐 음원이 발매되며 멜론, 지니등 음원사이트에서 여러분의 음원을 들어 보실 수 있습니다."
          />
          <Order
            className="left"
            number={9}
            src={ic_p9}
            title="영상 제작"
            content="음원을 발매하는 과정을 담은 메이킹 필름과 듣는하루에 참여하게 된 소감을 인터뷰 하는 비하인드 영상을 제작 해드립니다."
          />
        </Contents>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 900px;
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    height: auto;
    margin-top: 40px;
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    height: auto;
    margin-top: 40px;
  }
`;

const Contents = styled.div`
  padding-bottom: 100px;
`;
const H4 = styled.h4`
  display: flex;
  margin: 0 0 20px 0;
  span {
    font-size: 20px;
    border-bottom: 1px solid orange;
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    margin-top: 8px;
    span {
      font-size: 14px;
      padding-bottom: 8px;
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    margin-top: 8px;
    margin-left: 20px;
    span {
      font-size: 14px;
      padding-bottom: 8px;
    }
  }
`;
