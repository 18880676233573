import React from "react";
import styled from "styled-components";
import src from "@assets/banner.jpg";

export const Banner = () => {
  return (
    <div>
      <Img src={src} />
    </div>
  );
};

const Img = styled.img`
  width: 100%;
  height: calc(200px + 2vh);
  object-fit: fill;
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    height: 100px;
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    height: 70px;
  }
`;
