import React from "react";
import styled from "styled-components";
import { Album } from "./items/Album";
import white from "@assets/white-girl.jpg";
import mine from "@assets/MINE.jpg";
import girl from "@assets/GIRL-4RL.jpg";
import Carousel from "better-react-carousel";

export const Review = () => {
  return (
    <>
      <div className="after"></div>
      <Wrapper>
        <div>
          <h4 className="review">
            <span>REVIEW</span>
          </h4>
          <div className="carousel-wrapper">
            <Carousel cols={1} rows={1} loop>
              <Carousel.Item>
                <iframe
                  className="youtube"
                  src="https://www.youtube.com/embed/BV0aFpoIC3E"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Carousel.Item>
              <Carousel.Item>
                <iframe
                  className="youtube"
                  src="https://www.youtube.com/embed/vWw41QTnuB8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Carousel.Item>
              <Carousel.Item>
                <iframe
                  className="youtube"
                  src="https://www.youtube.com/embed/vWw41QTnuB8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Carousel.Item>
              <Carousel.Item>
                <iframe
                  className="youtube"
                  src="https://www.youtube.com/embed/vWw41QTnuB8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Carousel.Item>
              <Carousel.Item>
                <iframe
                  className="youtube"
                  src="https://www.youtube.com/embed/vWw41QTnuB8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="real-review">
            <h4 className="real-title">
              <span>참여자들의 후기</span>
            </h4>
            <div className="carousel-wrapper">
              <Carousel cols={1} rows={1} loop>
                <Carousel.Item>
                  <div className="real-after">
                    <Album src={white} />
                    <div className="real-content">
                      <div className="real-orange">
                        <div>
                          <strong>'White girl'을 발매한</strong>
                        </div>
                        <div>
                          <strong>김**님의 후기</strong>
                          <span className="customer-profile">
                            (24살/회사원)
                          </span>
                        </div>
                      </div>
                      <div className="real-black">
                        <p>특별한 날에 특별한 이벤트를 하니</p>
                        <p>그야말로 최고의 하루가 되었습니다.</p>
                        <p>잊을 수 없는 하루를 만들어 주셔서</p>
                        <p>감사합니다!</p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="real-after">
                    <Album src={mine} />
                    <div className="real-content">
                      <div className="real-orange">
                        <div>
                          <strong>'MINE'을 발매한</strong>
                        </div>
                        <div>
                          <strong>이**님의 후기</strong>
                          <span className="customer-profile">
                            (22살/가수지망생)
                          </span>
                        </div>
                      </div>
                      <div className="real-black">
                        <p>평소에 음악을 워낙 좋아해서</p>
                        <p>머릿속으로만 음악을 만들어 보는</p>
                        <p> 상상을 했었는데, 이렇게 제 목소리가</p>
                        <p> 앨범으로 나오니 신기합니다ㅎㅎ</p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="real-after">
                    <Album src={girl} />
                    <div className="real-content">
                      <div className="real-orange">
                        <div>
                          <strong>'GIRL 4RL'을 발매한</strong>
                        </div>
                        <div>
                          <strong> 최**님의 후기 </strong>
                          <span className="customer-profile">
                            (27살/예비신랑)
                          </span>
                        </div>
                      </div>
                      <div className="real-black">
                        <p>특별한 날에 특별한 이벤트를하니</p>
                        <p>그야말로 최고의 하루가 되었습니다.</p>
                        <p>잊을 수 없는 하루를 만들어주셔서 </p>
                        <p>감사합니다!</p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="real-after">
                    <Album src={girl} />
                    <div className="real-content">
                      <div className="real-orange">
                        <div>
                          <strong>'GIRL 4RL'을 발매한</strong>
                        </div>
                        <div>
                          <strong> 최**님의 후기 </strong>
                          <span className="customer-profile">
                            (27살/예비신랑)
                          </span>
                        </div>
                      </div>
                      <div className="real-black">
                        <p>특별한 날에 특별한 이벤트를하니</p>
                        <p>그야말로 최고의 하루가 되었습니다.</p>
                        <p>잊을 수 없는 하루를 만들어주셔서 </p>
                        <p>감사합니다!</p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
          <div className="btn">
            <button>지금 신청하기</button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  padding-top: 100px;
  .review {
    span {
      font-size: 40px;
      color: orange;
      border-bottom: 2px solid orange;
    }
  }
  .youtube {
    width: 900px;
    height: 600px;
  }
  .carousel-wrapper {
    width: 1000px !important;
    /* width: 100%; */
  }

  .review-footer {
    text-align: right;
  }
  .real-review {
    margin-top: 100px;
    .real-after {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-top: 80px;
    }
    .real-content {
      position: relative;
      width: 320px;
      height: 220px;
      background-color: white;
      border-radius: 16px;
      margin-left: 60px;
      padding: 40px;

      .real-orange {
        color: orange;
        text-align: left;
        font-size: 24px;
        .customer-profile {
          font-size: 16px;
        }
      }
      // 삼각형
      &::before {
        content: " ";
        position: absolute;
        top: 30px;
        left: -20px;
        height: 0;
        width: 0;
        position: absolute;
        border: 20px solid transparent;
        border-left-width: 0;
        border-right-color: white;
      }
      .real-black {
        margin-top: 40px;
        p {
          margin-bottom: 0;
          margin-top: 10px;
          text-align: left;
          font-size: 18px;
        }
      }
    }
    .real-link {
      color: orange;
      font-size: 22px;
      border-bottom: 1px solid orange;
      margin-left: 50px;
      font-family: NanumExtraBold;
    }
  }
  .real-title {
    text-align: left;
    span {
      font-size: 32px;
      border-bottom: 2px solid black;
    }
  }
  .btn {
    margin-top: 80px;
    margin-bottom: 100px;
    button {
      width: 200px;
      height: 70px;
      font-size: 32px;
      background-color: orange;
      border: none;
      color: white;
      border-radius: 2px;
    }
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    .review {
      span {
        font-size: 20px;
      }
    }
    .youtube {
      width: 320px;
      height: 300px;
    }

    .review-footer {
      font-size: 10px;
    }
    .real-review {
      .real-title {
        margin-left: 20px;
        span {
          font-size: 14px;
        }
      }
      .real-after {
        align-items: flex-start;
      }
      .real-content {
        position: relative;
        width: auto;
        height: auto;
        padding: 10px;
        margin-left: 10px;
        .real-orange {
          font-size: 12px;
          .customer-profile {
            font-size: 10px;
          }
        }
        .real-black {
          p {
            font-size: 10px;
          }
        }
      }
    }
    .btn {
      button {
        width: 120px;
        height: 50px;
        font-size: 16px;
      }
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    .carousel-wrapper {
      width: 400px !important;
      /* width: 100%; */
    }

    .review {
      span {
        font-size: 20px;
      }
    }
    .youtube {
      width: 320px;
      height: 300px;
    }
    .fold-wrapper {
      padding: 10px;
      .example {
        .inner-example {
          p {
            font-size: 10px;
          }
          span {
            font-size: 10px;
          }
        }
      }
    }
    .review-footer {
      font-size: 10px;
    }
    .real-review {
      .real-title {
        margin-left: 20px;
        span {
          font-size: 14px;
        }
      }
      .real-after {
        align-items: flex-start;
      }
      .real-content {
        position: relative;
        width: auto;
        height: auto;
        padding: 10px;
        margin-left: 10px;
        .real-orange {
          font-size: 12px;
          .customer-profile {
            font-size: 10px;
          }
        }
        .real-black {
          p {
            font-size: 10px;
          }
        }
      }
    }
    .btn {
      button {
        width: 120px;
        height: 50px;
        font-size: 16px;
      }
    }
  }
`;
