import React from "react";
import styled from "styled-components";

import youtube from "@assets/youtube.png";
import instagram from "@assets/ic-instagram.png";

export const Footer = () => {
  return (
    <Foot>
      <div className="foot-wrap">
        <div className="foot-left">
          <div className="foot-top">
            <span className="foot-bold">
              <b>듣는 하루</b>
            </span>
            <span>
              듣는하루 | 대표: 류현승 | 사업자 등록번호 : 543-42-00962
            </span>
            <span>주소: 서울특별시 송파구 송파대로 413</span>
          </div>
          <div className="foot-bottom">
            Copyright © ListenHaru All right reserved.
          </div>
        </div>
        <div className="foot-right">
          <span className="foot-bold">
            <b>SNS</b>
          </span>
          <div className="foot-icons">
            <img
              className="youtube"
              src={youtube}
              alt=""
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCSJJrodjaaGq4EL7-JKpZkg",
                  "_blank"
                )
              }
            />
            <img
              className="instagram"
              src={instagram}
              alt=""
              onClick={() =>
                window.open("https://www.instagram.com/listenharu/", "_blank")
              }
            />
          </div>
        </div>
      </div>
    </Foot>
  );
};

const Foot = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ivory;
  color: white;

  .foot-wrap {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    width: 100%;
    text-align: left;
    padding: 40px;
  }
  .foot-left {
    color: black;
    font-size: 13px;
    line-height: 13px;
    font-family: "AppleSDGothicNeo";
    .foot-top {
      span {
        display: block;
      }
      span + span {
        margin-top: 6px;
      }
    }
  }
  .foot-right {
    height: 90px;
    color: black;
    font-size: 13px;
    line-height: 13px;
    font-family: "AppleSDGothicNeo";

    span {
      display: block;
    }
    .youtube {
      width: 30px;
      height: 30px;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    img + img {
      margin-left: 12px;
    }
  }
  .foot-bold {
    margin-bottom: 16px;
  }
  .foot-bottom {
    color: grey;
    margin-top: 12px;
  }
  .foot-icons {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 479px) {
    .foot-wrap {
    }
  }
`;
