import React from "react";
import styled from "styled-components";
import src from "@assets/steps.png";
import useScrollFadeIn from "../hooks/useScrollFadeIn";

export const Difference = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0),
  };
  return (
    <Wrapper {...animatedItem[0]}>
      <h3 className="diff-title">듣는 하루는 무엇이 다를까요?</h3>
      <div className="arrows">
        <img src={src} />
      </div>
      <div>
        <h3 className="diff-under">
          <span>듣는 하루는</span>
        </h3>
        <div className="intro-first">
          <p>한 명의 프로듀서가 모든 일을 작업하지 않고,</p>
          <p>
            <strong>
              비트를 만드는 전문 비트메이커와 노래 멜로디를 만드는 전문
              탑라이너가
            </strong>
          </p>
          <p>
            <strong>팀을 이루어 각 분야를 전담하여 작업합니다.</strong>
          </p>
        </div>
        <div className="intro-second">
          <p>참여자님은 더 빠르고 전문적인 피드백이 가능하며 </p>
          <p>수준 높은 퀄리티의 음원제작이 가능합니다. </p>
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  background-color: #f3f0ea;
  min-height: 950px;
  .diff-title {
    color: orange;
    font-size: 24px;
    padding: 80px;
  }
  .arrows {
    display: flex;
    justify-content: center;

    img {
      width: 800px;
      border-radius: 30px;
    }
  }
  .diff-under {
    color: orange;
    font-size: 24px;
    span {
      padding-bottom: 30px;
      border-bottom: 1px solid orange;
    }
  }
  .intro-first {
    margin-top: 60px;
  }
  .intro-second {
    margin-top: 30px;
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    height: auto;
    min-height: 0;
    .diff-title {
      font-size: 16px;
      padding: 20px;
    }
    .arrows {
      img {
        width: 80%;
      }
    }
    .diff-under {
      font-size: 16px;
      span {
        padding-bottom: 10px;
      }
    }
    .intro-first {
      font-size: 12px;
    }
    .intro-second {
      font-size: 12px;
      padding-bottom: 60px;
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    height: auto;
    min-height: 0;
    .diff-title {
      font-size: 16px;
      padding: 20px;
    }
    .arrows {
      img {
        width: 100%;
      }
    }
    .diff-under {
      font-size: 16px;
      span {
        padding-bottom: 10px;
      }
    }
    .intro-first {
      font-size: 12px;
    }
    .intro-second {
      font-size: 12px;
      padding-bottom: 60px;
    }
  }
`;
