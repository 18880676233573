import React from "react";
import styled from "styled-components";
import ryukie from "@assets/Ryukie.png";
import bessi from "@assets/Bessi.png";
import JB from "@assets/JB.jpg";

export const Team = () => {
  return (
    <Wrapper>
      <h4 className="team">
        <span>TEAM</span>
      </h4>
      <div className="ryukie">
        <img className="profile" src={ryukie} />
        <div className="ryukie-info">
          <h4 className="ryukie-position">
            <span>RYUKIE </span>
            <label>/ BEAT MAKER</label>
          </h4>
          <ul>
            <li>BESSI - Satellite 프로듀싱</li>
            <li>BESSI - SKIP 프로듀싱</li>
            <li>BESSI - Care Bout you 프로듀싱</li>
            <li>RYUKIE - MINE 프로듀싱</li>
            <li>PERC%NT - Flower Scent 작곡</li>
            <li>미래 - CRASH OUT 프로듀싱</li>
            <li>SAGE - 눈 프로듀싱</li>
            <li>미래 - U 프로듀싱</li>
            <li>Kozypop - 4AM 프로듀싱</li>
            <li>고려진 - wavy 프로듀싱</li>
            <li>sweetmango - Wish you got my love 프로듀싱</li>
            <li>DOF2D - Come Closer 편곡</li>
            <li>RYUKIE - MINE 프로듀싱</li>
            <li>BESSI - All I need 프로듀싱</li>
            <li>BESSI - On me 프로듀싱</li>
            <li>BESSI - Sorry 프로듀싱</li>
            <li>BESSI - Escape 프로듀싱</li>
            <li>BESSI - Drive 프로듀싱</li>
            <li>BESSI - 지금 프로듀싱</li>
            <li>Kozypop - Take it Slow 프로듀싱</li>
            <li>RYUKIE - White Girl 프로듀싱</li>
            <li>Kuma - 시동 프로듀싱</li>
            <li>Kuma - 꾼 프로듀싱</li>
          </ul>
        </div>
      </div>
      <div className="ryukie">
        <img className="profile" src={bessi} />
        <div className="ryukie-info">
          <h4 className="ryukie-position">
            <span>BESSI </span>
            <label>/ TOP LINER</label>
          </h4>
          <ul>
            <li>BESSI - SKIP 발매</li>
            <li>BESSI - Satelite 발매</li>
            <li>BESSI - Care Bout you 발매</li>
            <li>BESSI - FASHION WEEK 발매</li>
            <li>TOM - 비행 피처링</li>
            <li>FLEXWAG - FASHION WEEK 앨범 수록</li>
            <li>BESSI - Wanna stay with U 작사 작곡</li>
            <li>BESSI - Sorry 발매</li>
            <li>BESSI - Escape 발매</li>
            <li>BESSI - Drive 발매</li>
            <li>BESSI - 지금 발매</li>
            <li>RYUKIE - MINE 피처링</li>
            <li>Kozypop - 4AM 작사 작곡 피처링</li>
            <li>BESSI - All I need 발매</li>
            <li>BESSI - On me 발매</li>
            <li>RYUKIE - White Girl 작사 작곡 피처링</li>
          </ul>
        </div>
      </div>
      <div className="ryukie">
        <img className="profile" src={JB} alt="" />
        <div className="ryukie-info">
          <h4 className="ryukie-position">
            <span>JB </span>
            <label>/ FILM DIRECTOR</label>
          </h4>
          <ul>
            <li>The SMC 그룹</li>
            <li>- PD [광고기획 , 콘텐츠 촬영 편집, 브랜드 마케팅]</li>
            <br />
            <li>뽀로로파크</li>
            <li> - 마케팅 [SNS 운영 , 마케팅, 광고 기획, 행사 기획] 티온</li>
            <br />
            <li>네트워크 하이컷</li>
            <li>- 콘텐츠 PD [콘텐츠 제작 및 광고 기획, 자사 유튜브 운영]</li>
            <br />
            <li>영상 작업 이력</li>
            <li>
              - 아티스트 : 아이유, 수지, 세븐틴, 블랙핑크(제니) , 소녀시대
              (윤아,태연), 레드벨벳 (예리), 트와이스 (정연), 마마무 (화사),
              강다니엘, NCT (재현), SF9 (로운), 모모랜드, 키썸, 윤도현,
              가비앤제이, BDC 등
            </li>
            <li>
              - 배우 : 하정우, 이병헌, 김남길, 한지민, 김다미, 남주혁, 김향기,
              조정석, 신세경, 이재욱, 김혜윤, 박민영, 권상우, 김성균, 노사연,
              이유비, 차정원, 이하늬, 서지혜, 이신영, 손담비, 변정수, 조세호 등
            </li>
            <li>
              - 셀럽 : 드랙 아티스트 (네온밀크), DJ (맥시마이트), 헤어디자이너
              (수), 1million 댄서 (박봉영), 모델 (유채원,락채은,퓨어디,김세린),
              유튜버 (스칼렛,혜인), UFC 선수(정찬성, 김동현, 강경호, 김지현) 등
            </li>
            <li>
              - 브랜드 : 삼성갤럭시, 롯데, 신라면세점, JYP CHINA, Warner Bros,
              Sensation, LIA, 핸슨소파, UFC 코리아 등
            </li>
            <li>
              - 채널 : 1theK Original, 모노튜브, 홍쓰메이크업플레이, 신차백과,
              오토포스트, 김승현 안피디 스포일러, 피플박스, 누콜 매트리스,
              한국갤러리몰, 롯데시네마 등
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  br {
    margin-top: 10px;
  }
  li {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .team {
    padding: 20px;
    span {
      font-size: 40px;
      font-weight: 500;
      padding-bottom: 10px;
      font-family: NanumBold;
      color: orange;
      border-bottom: 3px solid orange;
    }
  }
  .ryukie {
    display: flex;
    justify-content: center;

    .profile {
      width: 450px;
      height: 450px;
    }
    .ryukie-info {
      font-family: "NotoSansKR-Medium";
      background-color: #f9f9f9;
      width: 380px;
      padding: 20px 0 20px 40px;
      .ryukie-position {
        text-align: left;
        span {
          font-size: 32px;
        }
      }
      ul {
        list-style: none;
        padding: 0;
        text-align: left;
      }
    }
  }
  .ryukie + .ryukie {
    margin-top: 40px;
    margin-bottom: 150px;
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    .team {
      span {
        font-size: 20px;
        border-bottom: 2px solid orange;
      }
    }
    .ryukie {
      flex-direction: column;
      align-items: center;
      .profile {
        width: 250px;
        height: 250px;
        border-radius: 2px;
      }
      .ryukie-info {
        max-width: 400px;
        width: 100%;
        margin-top: 20px;
        padding-left: 75px;
        .ryukie-position {
          span {
            font-size: 20px;
          }
        }
        ul {
          font-size: 12px;
        }
      }
    }
    .ryukie + .ryukie {
      margin-bottom: 40px;
    }
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    .team {
      span {
        font-size: 20px;
        border-bottom: 2px solid orange;
      }
    }
    .ryukie {
      flex-direction: column;
      align-items: center;
      .profile {
        width: 250px;
        height: 250px;
        border-radius: 2px;
      }
      .ryukie-info {
        max-width: 280px;
        width: 100%;
        margin-top: 20px;
        padding-left: 30px;
        .ryukie-position {
          span {
            font-size: 20px;
          }
        }
        ul {
          font-size: 12px;
        }
      }
    }
    .ryukie + .ryukie {
      margin-bottom: 40px;
    }
  }
`;
