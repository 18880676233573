import React from "react";
import styled from "styled-components";
import useScrollFadeIn from "../hooks/useScrollFadeIn";
import { Order } from "./items/Order";
import ic_p4 from "@assets/ic-p4.png";
import ic_p5 from "@assets/ic-p5.png";
import ic_p6 from "@assets/ic-p6.png";

export const Process2 = () => {
  const animatedItem = {
    0: useScrollFadeIn("up", 1, 0),
  };
  return (
    <Wrapper {...animatedItem[0]}>
      <div className="process-wrap">
        <Contents>
          <Order
            className="right"
            number={4}
            src={ic_p4}
            title="보컬 레슨"
            content="가사 완성 후 전문 트레이너에게 연습 노하우와 보컬 레슨을 받게 됩니다"
            detail="(음치도 박치도 OK!)"
          />
          <Order
            className="right"
            number={5}
            src={ic_p5}
            title="레코딩"
            content="메인 프로듀서의 디렉팅을 받으며 2회에 걸쳐 녹음을 진행합니다"
            detail="(총 2회, 약 6시간)"
          />
          <Order
            className="right"
            number={6}
            src={ic_p6}
            title={`믹스 & 마스터링`}
            content={`음정 박자 튠과 함께 믹스 & 마스터링을 시작합니다`}
            detail=""
          />
        </Contents>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 900px;
  background-color: #f2f2f0;
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
    height: auto;
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    height: auto;
  }
`;

const Contents = styled.div``;
