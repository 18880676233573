import React from "react";
import styled from "styled-components";
import disk from "@assets/disk.png";

export const Album = ({ src }) => {
  return (
    <Wrapper>
      <img className="cover" src={src} />
      <img className="disk" src={disk} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 360px;
  height: 360px;
  position: relative;
  .cover {
    width: 360px;
    height: 360px;
  }
  .disk {
    position: absolute;
    left: -70px;
    bottom: -40px;
    width: 250px;
    height: 250px;
  }
  @media only screen and (max-width: 1200px) {
    // tablet
  }
  @media only screen and (max-width: 767px) {
    // mobile 가로
  }
  @media only screen and (max-width: 479px) {
    // mobile 세로
    width: 150px;
    height: 150px;
    position: relative;
    .cover {
      width: 150px;
      height: 150px;
    }
    .disk {
      position: absolute;
      width: 90px;
      height: 90px;
      left: -20px;
      top: 75px;
    }
  }
`;
