import React from "react";
import styled from "styled-components";
import Src from "@assets/ttt.png";

function MainBottom() {
  return (
    <Wrapper>
      <img src={Src} alt="main-bottom" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100px;
  img {
    width: 100%;
    object-fit: fill;
    margin-bottom: -5px;
    margin-top: -5px;
  }
`;
export default MainBottom;
